<template>
  <div class="d-lg-flex half" v-if="!isAuthenticated">
    <div class="contents order-1 order-md-2">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-7">
            <b-row class="mb-5">
              <img
                class="login-image"
                src="@/assets/img/auth/upl-treko-rev.jpg"
                alt="UPL-LOGO"
              />
            </b-row>

            <span class="auth-400">
              Welcome back
            </span>

            <p class="auth-700 mb-4">
              Login to your Account
            </p>

            <b-form @submit="login" v-if="show">
              <b-form-group
                id="input-email-ugdn"
                label="E-Mail"
                label-for="email/ugdn"
                class="auth-400"
              >
                <b-form-input
                  id="email/ugdn"
                  v-model="form.id"
                  type="text"
                  placeholder="Masukkan alamat email anda"
                  class="auth-border-clr pt-4 pb-4 pl-3"
                  required
                >
                </b-form-input>
              </b-form-group>

              <!-- <div class="input-group mb-3 auth-400">
                <input type="text" class="form-control" placeholder="Recipient's username"
                  aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">@example.com</span>
                </div>
              </div> -->

              <b-form-group
                inline
                id="input-password"
                label="Password"
                label-for="password"
                class="auth-400"
              >
                <div class="input-group mb-3">
                  <input
                    id="password"
                    :type="[showPassword ? 'text' : 'password']"
                    class="form-control auth-border-clr auth-password pt-4 pb-4 pl-3"
                    placeholder="Masukkan password anda"
                    v-model="form.password"
                    required
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text input-append auth-border-clr bg-white"
                      id="basic-addon2"
                      @click="showPassword = !showPassword"
                    >
                      <i v-if="showPassword" class="ri-eye-line"></i>
                      <i v-else class="ri-eye-off-line"></i>
                    </span>
                  </div>
                </div>
              </b-form-group>

              <b-row>
                <b-col cols="6">
                  <router-link
                    :to="{ name: 'manual' }"
                    class="float-left auth-rbt upl-clr"
                    >Need help?
                  </router-link>
                </b-col>
                <b-col cols="6">
                  <router-link
                    :to="{ name: 'reset' }"
                    class="float-right auth-rbt upl-clr"
                    >Forgot password?
                  </router-link>
                </b-col>
              </b-row>

              <button
                id="login-button"
                type="submit"
                class="btn btn-primary login-btn w-100 mt-5"
              >
                <div class="spinner d-inline">
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
                <span class="ml-2 d-inline">
                  Login now
                </span>
              </button>
            </b-form>
          </div>
        </div>
      </div>
    </div>

    <div class="bg order-2 order-md-1 login-img">
      <b-carousel id="carousel-fade" :interval="interval">
        <b-carousel-slide
          v-for="index in 11"
          :key="index"
          :img-src="require(`@/assets/img/auth/login${index}.jpg`)"
        >
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGIN, CLEAR } from "@/core/services/store/auth.module";

export default {
  name: "login",
  data() {
    return {
      form: {
        id: "",
        password: ""
      },
      type: "password",
      showPassword: false,
      remember: false,
      show: true,
      loading: false,
      interval: 2500
    };
  },
  components: {},
  methods: {
    resetForm() {
      this.form = {
        password: ""
      };
    },
    login(e) {
      e.preventDefault();

      // clear existing errors
      this.$store.dispatch(CLEAR);
      if (this.loading == false) {
        this.loading = true;
        const loginBtn = document.querySelector("#login-button");
        loginBtn.classList.add("disabled");
      }

      // dummy delay
      setTimeout(async () => {
        // send login request
        const email = this.form.id;
        const ugdn = this.form.id;
        const password = this.form.password;

        const validNumber = /^[0-9]{8}$/;

        if (validNumber.test(this.form.id)) {
          this.$store
            .dispatch(LOGIN, { ugdn, password })
            // go to which page after successfully login
            .then(() => {
              this.loading = false;
              switch (this.currentUser.role) {
                case "SUPER_ADMIN":
                  this.$router.push({ name: "admin-dashboard" });
                  break;
                case "CM":
                  this.$router.push({ name: "cm-dashboard" });
                  break;
                case "ADVISOR":
                  this.$router.push({ name: "adv-dashboard" });
                  break;
                case "NSM":
                  this.$router.push({ name: "nsm-dashboard" });
                  break;
                case "NMM":
                  this.$router.push({ name: "nmm-dashboard" });
                  break;
                case "SA":
                  this.$router.push({ name: "sa-dashboard" });
                  break;
                case "MT":
                  this.$router.push({ name: "mt-dashboard" });
                  break;
                case "RM":
                  this.$router.push({ name: "rm-dashboard" });
                  break;
                case "AM":
                  this.$router.push({ name: "am-dashboard" });
                  break;
                case "TSS":
                  this.$router.push({ name: "tss" });
                  break;
                case "SP":
                  this.$router.push({ name: "sp-dashboard" });
                  break;
              }
            })
            .catch(() => {
              if (this.errorData) {
                this.loading = false;
                this.resetForm();

                this.$swal.fire({
                  icon: "error",
                  title: "Login Gagal!",
                  text: this.errorData,
                  width: 400,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#fd8833"
                });

                const loginBtn = document.querySelector("#login-button");
                loginBtn.classList.remove("disabled");
              }
            });
        } else {
          this.$store
            .dispatch(LOGIN, { email, password })
            // go to which page after successfully login
            .then(() => {
              this.loading = false;
              switch (this.currentUser.role) {
                case "SUPER_ADMIN":
                  this.$router.push({ name: "admin" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
                case "CM":
                  this.$router.push({ name: "cm-dashboard" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
                case "NSM":
                  this.$router.push({ name: "nsm-dashboard" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
                case "NMM":
                  this.$router.push({ name: "nmm-dashboard" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
                case "SA":
                  this.$router.push({ name: "sa-dashboard" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
                case "MT":
                  this.$router.push({ name: "mt-dashboard" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
                case "RM":
                  this.$router.push({ name: "rm-dashboard" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
                case "AM":
                  this.$router.push({ name: "am-dashboard" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
                case "TSS":
                  this.$router.push({ name: "tss-dashboard" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
                case "SP":
                  this.$router.push({ name: "sp-dashboard" });
                  if (this.currentUser.mustReset) {
                    this.resetAlert();
                  }
                  break;
              }
            })
            .catch(() => {
              if (
                this.errorData ===
                "GraphQL error: Error: Website Tidak Tersedia untuk SP"
              ) {
                this.loading = false;
                this.$swal.fire({
                  icon: "error",
                  title: "Login Gagal!",
                  text: "Website tidak tersedia untuk SP",
                  width: 400,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#fd8833"
                });
                const loginBtn = document.querySelector("#login-button");
                loginBtn.classList.remove("disabled");
              } else {
                this.loading = false;
                this.$swal.fire({
                  icon: "error",
                  title: "Login Gagal!",
                  text: this.errorData,
                  width: 400,
                  confirmButtonText: "OK",
                  confirmButtonColor: "#fd8833"
                });
                const loginBtn = document.querySelector("#login-button");
                loginBtn.classList.remove("disabled");
              }
            });
        }
      }, 1000);
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "errorData", "currentUser"])
  },
  created() {
    // let interval = window.setInterval(this.test, 200);
    // setTimeout(() => clearInterval(interval), 1000);
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "admin-dashboard" });
    }
  }
};
</script>

<style>
.auth-400 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.11px;
  color: #000;
}

.auth-700 {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #000;
}

.carousel-item img {
  height: 100vh !important;
  object-fit: cover;
  object-position: center;
}

.input-append {
  border-left: 0 !important;
  font-size: 20px !important;
}

.auth-password {
  border-right: 0 !important;
}

.auth-border-clr {
  border-color: #c4c4c4 !important;
}

.checkbox-round {
  width: 17px;
  height: 17px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1.5px solid #c4c4c4;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-image: url("../../assets/img/auth/checked.png");
  background-position: center;
}

.auth-rbt {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.upl-clr {
  color: #fd8833;
}

.upl-clr:hover {
  color: #ff6a00;
}

.login-btn {
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.login-btn:focus,
.login-btn:active {
  background-color: #fd8833 !important;
  border-color: #fd8833 !important;
}

body {
  /* font-family: "Roboto", sans-serif; */
  background-color: #fff;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

a:hover {
  text-decoration: none !important;
}

.content {
  padding: 7rem 0;
}

.half,
.half .container > .row {
  height: 100vh;
}

@media (max-width: 991.98px) {
  .half .bg {
    height: 500px;
    display: none;
  }
}

.half .contents,
.half .bg {
  width: 50%;
}

@media (max-width: 1199.98px) {
  .half .contents,
  .half .bg {
    width: 100%;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  color: #c4c4c4;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  color: #c4c4c4;
}

::placeholder {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  color: #c4c4c4;
}

.eye-icon {
  color: #3f4254;
}

.login-image {
  width: 140px;
  margin-left: 16px;
}
</style>
